@tailwind base;
@tailwind components;
@tailwind utilities;

html,body,:root{
  height: 100%;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 138.7 92.6% 34.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --swiper-pagination-color: #166534;
    --removed-body-scroll-bar-size: 10px;

  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;

  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

html {
  font-family: var(--font-manrope);
  color: #111314;
} 
body{
  overflow-x: hidden;
}
a{
  @apply outline-none;
}
.product-details h4 {
  @apply font-medium mb-3 underline underline-offset-4;
}
.product-details ul,
.recipe-details ul {
  @apply list-disc ml-4 mb-4 text-gray-700;
}
/* 
.font-title {
  font-family: var(--font-londrina-solid);
} */

.pagination-dot {
  @apply bg-gray-400 rounded-full w-4 h-4;
}
.recipe-details p {
  @apply mb-3;
}
.recipe-details h4 {
  @apply mb-3 text-xl;
}

.recipe-details ol {
  list-style: auto;
  @apply ml-5 mb-4 text-gray-800;
}

.reviews-slide .swiper-slide{
  height: auto;
}

.reviews-slide{
  padding-bottom: 2.5rem !important;
}

.footer-link{
  @apply text-slate-200 text-sm font-medium duration-200 hover:underline hover:underline-offset-4
}
.swiper-pagination{
  bottom: 0px !important;
}

.privacy-policy h1{
  @apply text-3xl font-bold mb-5;
}
.privacy-policy h2{
  @apply text-2xl font-bold mb-5;
}

.privacy-policy h3{
  @apply text-xl font-semibold my-5;
}

.privacy-policy table td, .privacy-policy table th{
  @apply text-sm border text-left border-collapse border-gray-300 p-2;
}
.privacy-policy h4{
  @apply text-lg font-medium my-5;
}
.privacy-policy p{
  @apply text-sm lg:text-base mb-4;
}
.privacy-policy ul{
  @apply list-disc my-4 text-gray-700;
}
.privacy-policy li{
  @apply ml-4 text-sm lg:text-base;
}


.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}